import {
  FieldType,
  TournamentFieldsFragmentFragment,
} from '@/__generated__/graphql.ts';

export const TournamentType = {
  Regular: 'regular',
  Prediction: 'prediction',
};
export type TournamentType =
  (typeof TournamentType)[keyof typeof TournamentType];

export const determinateTournamentType = (
  fields?: TournamentFieldsFragmentFragment[],
) => {
  if (!fields) return null;
  return fields.some((field) => field?.type === FieldType.Percentage)
    ? TournamentType.Prediction
    : TournamentType.Regular;
};
